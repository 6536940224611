<template>
  <div
    ref="drawerRef"
    class="<lg:fixed <lg:bottom-0 <lg:z-1 <lg:max-h-full <lg:w-full <lg:f-col <lg:translate-y-full <lg:touch-none <lg:rounded-t-lg <lg:bg-white <lg:shadow-lg"
    :class="{ '<lg:cursor-grabbing': isDragging, '<lg:transition-transform': allowAnimation && !isDragging }"
    :style="drawerPositionStyle"
    @keydown.esc="collapseDrawer"
  >
    <base-button
      class="w-full py-3 outline-none lg:hidden"
      :class="{ relative: enablePageScrollBehindPdpDrawer }"
      @click.prevent="toggleDrawer"
    >
      <span class="mx-a block w-14 rounded-lg bg-grey-70" style="height: 0.125rem" />
      <span v-if="enablePageScrollBehindPdpDrawer" class="absolute-0 -translate-y-full" />
    </base-button>
    <div
      ref="drawerContentRef"
      class="grow <lg:px-4"
      :class="{ '<lg:overflow-auto': isExpanded }"
      @wheel.passive="handleScroll"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    minHeight?: number
  }>(),
  {
    minHeight: 320
  }
)

const drawerRef = ref()
const drawerContentRef = ref()

const { lock, unlock } = useBodyScroll()
const { enablePageScrollBehindPdpDrawer } = useFeatureFlags()
const { $viewport } = useNuxtApp()
const { y: drawerContentY } = useScroll(drawerContentRef)
const windowHeight = useWindowSize()

const collapsedPosition = `calc(100dvh - ${pxToRem(props.minHeight)})`
const expandedPosition = 100 // 100svh

const isExpanded = ref(false)
const isShown = ref(false)
const allowAnimation = ref(false)

const position = ref<string | number>(collapsedPosition)

let previousPosition = 0
let previousTime = 0
let velocity = 0

const drawerPositionStyle = computed(() => {
  if (!isShown.value || $viewport.lg) return {}

  const drawerPosition = (typeof position.value === 'string') ? position.value : `${expandedPosition - Math.min(position.value, expandedPosition)}dvh`

  return { transform: `translateY(${drawerPosition})` }
})

// When can only allow animations to happen when the user manually triggers it by tapping on the drawer
const enableAnimation = () => {
  allowAnimation.value = true
  setTimeout(() => allowAnimation.value = false, 200)
}

const collapseDrawer = () => {
  enableAnimation()

  isExpanded.value = false
  position.value = collapsedPosition
  drawerContentY.value = 0
  unlock()
}

const expandDrawer = () => {
  enableAnimation()

  isExpanded.value = true
  position.value = expandedPosition
  lock()
}

watch($viewport, () => {
  if ($viewport.lg)
    collapseDrawer()
})

const toggleDrawer = () => isExpanded.value ? collapseDrawer() : expandDrawer()
const currentDirection = ref('none')

const preventDragging = computed(
  () => drawerContentY.value > 0 || (isExpanded.value && currentDirection.value !== 'down')
)

let pointerOffset

const { isDragging, isMoving } = useVfDraggable(drawerRef, {
  preventDefault: true,
  axis: 'y',

  onStart: (e: PointerEvent) => {
    if ($viewport.lg) return false

    const drawerRect = drawerRef.value?.getBoundingClientRect()
    if (!drawerRect) return false

    pointerOffset = e.clientY - drawerRect.top

    previousPosition = e.clientY
    currentDirection.value = 'none'

    previousTime = Date.now()

    return true
  },
  onMove: (e: PointerEvent) => {
    if (currentDirection.value === 'none')
      currentDirection.value = e.movementY < 0 ? 'up' : 'down'

    if ($viewport.lg || (currentDirection.value !== 'none' && preventDragging.value))
      return false

    // Update drawer height dynamically during drag
    position.value = 100 - (((e.clientY - pointerOffset) / window.innerHeight) * 100)

    const currentTime = Date.now()
    const elapsedTime = (currentTime - previousTime) / 10

    if (elapsedTime > 0)
      velocity = (e.clientY - previousPosition) / elapsedTime

    previousPosition = e.clientY
    previousTime = currentTime

    return true
  },
  onEnd: () => {
    const friction = 0.95 // A friction factor to simulate deceleration
    const drawerRect = drawerRef.value?.getBoundingClientRect()
    let predictedPosition = drawerRect.top
    let currentVelocity = velocity

    while (Math.abs(currentVelocity) > 0.1) {
      predictedPosition += currentVelocity
      currentVelocity *= friction
    }

    if ((predictedPosition / window.innerHeight) * 100 > expandedPosition / 2)
      collapseDrawer()
    else
      expandDrawer()
  },
})

// updates the position based on when the window resizes. Useful for when the mobile browser hides/shows the toolbar when scroll
watch(windowHeight.height, () => {
  position.value = isExpanded.value ? expandedPosition : collapsedPosition
})

const handleScroll = (e: WheelEvent) => {
  if ($viewport.lg) return false
  else if (!isExpanded.value && e.deltaY > 0) expandDrawer()
}

onMounted(() => {
  enableAnimation()
  isShown.value = true
})

defineExpose({
  collapse: collapseDrawer,
  expand: expandDrawer,
  expanded: computed(() => typeof position.value === 'number' && position.value > expandedPosition * 0.7),
  isCollapsed: computed(() => !isExpanded.value && !isMoving.value),
  toggle: toggleDrawer
})
</script>

<template>
  <base-toggle
    v-bind="{ modelValue, disabled }"
    :aria-label="accessibilityLabel"
    class="i-flex"
    data-test-id="vf-switch"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <span
      aria-hidden="true"
      class="flex items-center pr-2 text-sm c-grey-10"
      :class="{ 'c-grey-20 c-grey-30': disabled }"
      data-test-id="vf-switch-label-off"
    >
      {{ labelOff }}
    </span>
    <span
      class="w-14 flex rounded-full duration peer-focus-visible:outline-auto !w-12"
      :class="[
        modelValue ? 'bg-brand-1 ' : 'bg-grey-60 ',
        { 'c-grey-70 bg-grey-70': disabled, '!bg-grey-60': disabled && modelValue },
      ]"
      data-test-id="vf-switch-track"
      :style="brandStyles.switch"
    >
      <span
        class="h-6 w-6 transform rounded-full bg-white duration !h-5 !w-5"
        :class="{ 'translate-x-full': modelValue }"
        data-test-id="vf-switch-handle"
      />
    </span>
    <span
      aria-hidden="true"
      class="flex items-center pl-2 text-sm c-grey-10"
      :class="{ 'c-grey-20 c-grey-30': disabled }"
      data-test-id="vf-switch-label-on"
    >
      {{ labelOn }}
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = defineProps<{
  /**
   * Sets the current value of the switch
   */
  modelValue?: boolean
  /**
   * Sets the disabled state of the switch
   */
  disabled?: boolean
  /**
   * Label for 'off' position of the switch
   */
  labelOff?: string
  /**
   * Label for 'on' position of the switch
   */
  labelOn?: string
}>()

defineEmits<{
  /**
   * Emits the active value on each change
   */
  (e: 'update:modelValue', payload: boolean | string[]): void
}>()

const { $t } = useNuxtApp()
const { brandStyles } = useAppConfig().components.vf.switch

const accessibilityLabel = computed(() => {
  if (props.labelOff && props.labelOn) {
    return replaceAll($t.switchAccessibilityLabel, {
      labelFrom: props.modelValue ? props.labelOn : props.labelOff,
      labelTo: props.modelValue ? props.labelOff : props.labelOn
    })
  }

  if (props.labelOff || props.labelOn)
    return replaceAll($t.switchAccessibilityLabelSingleLabel, { label: props.labelOff || props.labelOn })

  return $t.switchAccessibilityLabelNoLabels
})
</script>

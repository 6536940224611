<template>
  <base-button
    v-style="{ w: s, h: s }"
    :aria-label="paused ? $t.videoPlay : $t.videoPause"
    class="lg:group flex center p-2 pr-0 c-white"
  >
    <svg aria-hidden="true" class="full" viewBox="0 0 100 100">
      <circle
        class="fill-brand-1/50"
        :class="{ 'group-hover:fill-brand-1/90': !active }"
        cx="50"
        cy="50"
        fill="none"
        r="45"
        stroke="white"
        stroke-opacity="0.16"
        stroke-width="5"
      />
      <!-- Circular Progress Foreground -->
      <circle
        v-if="active"
        class="transition-all"
        cx="50"
        cy="50"
        fill="none"
        r="45"
        stroke="white"
        stroke-width="5"
        :style="{
          strokeDasharray: circumference,
          strokeDashoffset: offset,
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        }"
      />
    </svg>
    <span class="absolute flex center rounded-full">
      <vf-icon
        v-if="active"
        class="transform duration group-hover:scale-112"
        :name="paused ? 'play' : 'pause'"
        :size
      />
      <span v-else class="text-sm">
        {{ inactiveLabel }}
      </span>
    </span>
  </base-button>
</template>

<script setup lang="ts">
import type { IconSizes } from '#types/sizes'
import type { Responsive } from '#types/common'

const props = withDefaults(defineProps<{
  inactiveLabel?: string
  paused?: boolean
  active?: boolean // When we have multiple controls, like inside a carousel, we should present the play/pause only on the active video control
  progress?: number
  size?: IconSizes | Partial<Responsive<IconSizes>>
}>(), { active: true, progress: 0, inactiveLabel: '' })

const config = {
  xs: '2.5rem',
  sm: '2.75rem',
  md: '3rem',
  lg: '3.25rem',
}

const circumference = Math.PI * 90
const offset = computed(() => circumference - props.progress * circumference)

const s = computed(() =>
  mapObject(isObject(props.size) ? props.size : { sm: props.size }, (br) => `${config[br!]}`)
)
</script>
